.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #fff; */
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;;
}

.App-store {
  /* margin: 0px 50px 0px 50px; */
  min-height: 30vh;
  /* background-color: #dfff; */
  display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.App-store p {
  width: 60%;
}
.App-link {
  margin: 30px;
  height: 8vmin;
}
