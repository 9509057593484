
/* ------ */
/* MODAL PANEL */
/* ------ */
.modalPanel {
  z-index: 1000;
  position: absolute;
  padding:10px;
  top: 70px;
  left: 50px;
  right: 60px;
  display: flex;
  flex-direction: column;
  background-color:rgba(13, 12, 13, 0.69);
  border-radius:10px;
  color:#fff;
  font-family: 'Roboto', sans-serif;
  word-wrap: break-word;
}

.codePanel {
  background-color:rgba(125, 126, 134, 0.4);
  /* padding:5px; */
  margin:auto;
  /* height:80px; */
  border-radius:10px;
}

.modalTitle {
  display:flex;
  flex-direction:column;
  align-items: center;
  color:#fff;
}

.modalInfo {
  background-color:#303030d7;
  padding: 15px;
  border-radius:10px;
  min-width: 90%;
  align-items: center;
  text-align: center;
 
}
.modalWallet {
  width:50px;
  /* margin:auto; */
  margin:auto;
  margin-top: 5px;
  /* background-color: #fff; */
  border-radius: 10px;
 
}
.modalImage {
  width:70px;
  /* background-color: #fff; */
  border-radius: 10px;
  margin:auto;
  margin-bottom: 5px;
  
 }
 .modalLogo {
  width:90px;
  margin:auto;
  margin-top: 30px;
 }
 